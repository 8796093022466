/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl } from '@ws1c/intelligence-common/constants/feature-control.enum';
import { AdminRoleType, IntegratedServiceType, ScopeSetAccessOption } from '@ws1c/intelligence-models';

/**
 * AdminManagementConfig
 *
 * @export
 * @class AdminManagementConfig
 */
export class AdminManagementConfig {
  public static readonly MIN_SEARCH_TERM_LENGTH: number = 3;
  public static readonly GROUP_TYPE_USER_GROUP: string = 'USER_GROUP';

  public static readonly SERVICE_TYPE_TO_LABEL_MAP: Record<IntegratedServiceType, string> = {
    [IntegratedServiceType.INTELLIGENCE]: 'ADMIN_MGMT_COMMON_MESSAGES.INTELLIGENCE',
    [IntegratedServiceType.WS1_UEM]: 'ADMIN_MGMT_COMMON_MESSAGES.UEM',
    [IntegratedServiceType.WS1_ACCESS]: 'COMMON_MESSAGES.ACCESS',
    [IntegratedServiceType.HUB_SERVICES]: 'ADMIN_MGMT_COMMON_MESSAGES.INTELLIGENT_HUB_SERVICES',
    [IntegratedServiceType.HORIZON]: 'ADMIN_MGMT_COMMON_MESSAGES.HORIZON',
  };

  public static readonly ROLE_TYPE_LABEL_MAP: Record<AdminRoleType, string> = {
    [AdminRoleType.PRECANNED]: 'ROLES.SYSTEM',
    [AdminRoleType.CUSTOM]: 'ROLES.CUSTOM',
  };

  public static readonly SERVICE_TYPE_TO_FEATURE_FLAG_MAP: Record<IntegratedServiceType, FeatureControl> = {
    [IntegratedServiceType.INTELLIGENCE]: FeatureControl.WORKSPACE_ONE_RBAC_ENABLED,
    [IntegratedServiceType.WS1_UEM]: FeatureControl.WORKSPACE_ONE_UEM_RBAC_ENABLED,
    [IntegratedServiceType.WS1_ACCESS]: FeatureControl.WORKSPACE_ONE_ACCESS_RBAC_ENABLED,
    [IntegratedServiceType.HUB_SERVICES]: FeatureControl.WORKSPACE_ONE_HUBSERVICES_RBAC_ENABLED,
    [IntegratedServiceType.HORIZON]: FeatureControl.WORKSPACE_ONE_HORIZON_RBAC_ENABLED,
  };
  public static readonly INVITE_ADMIN_EMAIL_PATTERN: RegExp =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static readonly USER_PROPERTY: string = 'user';
  public static readonly GROUP_MEMBERSHIP_TYPE: string = 'GROUP';

  public static readonly SCOPE_ACCESS_LABEL_MAP: Record<ScopeSetAccessOption, string> = {
    [ScopeSetAccessOption.NO_ACCESS]: 'ROLES.NO_ACCESS',
    [ScopeSetAccessOption.EDIT]: 'ROLES.EDIT_ACCESS',
    [ScopeSetAccessOption.READ_ONLY]: 'ROLES.READ_ONLY',
    [ScopeSetAccessOption.EXECUTE]: 'ROLES.EXECUTE',
  };
}
