/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AdminManagementConfig } from '@ws1c/admin-management/const';
import {
  AdminGroupListItem,
  AdminManagementState,
  AdminUserListItem,
  IntegrationOrgGroup,
  PlatformRole,
  RolesState,
  ServiceRole,
} from '@ws1c/admin-management/models';
import { AdminGroupsSelectors } from '@ws1c/admin-management/store/admin-groups/admin-groups.selectors';
import { selectAdminManagementState } from '@ws1c/admin-management/store/admin-management.selector';
import { AdminUsersSelectors } from '@ws1c/admin-management/store/admin-users/admin-users.selectors';
import { getRoleLabelFormatterFn, getServiceRolesForAssignment } from '@ws1c/admin-management/utils';
import { IntegratedServicesSelectors, UserPreferenceCommonSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import {
  Account,
  AccountRole,
  AccountsSearchResponse,
  FeatureControls,
  GenericSearchRequest,
  IntegratedServiceType,
  Integration,
  IntegrationDetails,
  LOAD_STATE,
  RolesSearchResponse,
  ScopeSetAccessOption,
  ScopeSetSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * RolesSelectors
 *
 * @export
 * @class RolesSelectors
 */
export class RolesSelectors {
  /**
   * Selects the roles state slice from Admin Management store
   * @static
   * @type {MemoizedSelector<AdminManagementState, RolesState>}
   * @memberof RolesSelectors
   */
  public static selectRolesState: MemoizedSelector<AdminManagementState, RolesState> = createSelector(
    selectAdminManagementState,
    (state: AdminManagementState) => state.roles,
  );

  /**
   * getIntelligenceRolesSearchRequest
   * @static
   * @type {MemoizedSelector<AdminManagementState, GenericSearchRequest>}
   * @memberof RolesSelectors
   */
  public static getIntelligenceRolesSearchRequest: MemoizedSelector<AdminManagementState, GenericSearchRequest> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.intelligenceRolesRequest,
  );

  /**
   * isDeactivateModalOpen
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isDeactivateModalOpen: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isDeactivateModalOpen,
  );

  /**
   * externalServiceRolesByIntegrationId
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, RolesSearchResponse>>}
   * @memberof RolesSelectors
   */
  public static externalServiceRolesByIntegrationId: MemoizedSelector<AdminManagementState, Record<string, RolesSearchResponse>> =
    createSelector(RolesSelectors.selectRolesState, (state: RolesState) => state.externalServiceRolesByIntegrationId);

  /**
   * externalServiceRolesMap
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, AccountRole>>}
   * @memberof RolesSelectors
   */
  public static externalServiceRolesMap: MemoizedSelector<AdminManagementState, Record<string, AccountRole>> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.externalServiceRolesMap,
  );

  /**
   * serviceRolesLoadStateByIntegrationId
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, LOAD_STATE>>}
   * @memberof RolesSelectors
   */
  public static serviceRolesLoadStateByIntegrationId: MemoizedSelector<AdminManagementState, Record<string, LOAD_STATE>> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.serviceRolesLoadStateByIntegrationId,
  );

  /**
   * intelligenceRolesLoadingStatus
   * @static
   * @type {MemoizedSelector<AdminManagementState, LOAD_STATE>}
   * @memberof RolesSelectors
   */
  public static intelligenceRolesLoadingStatus: MemoizedSelector<AdminManagementState, LOAD_STATE> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.intelligenceRolesLoadingStatus,
  );

  /**
   * intelligenceRolesResponse
   * @static
   * @type {MemoizedSelector<AdminManagementState, RolesSearchResponse>}
   * @memberof RolesSelectors
   */
  public static intelligenceRolesResponse: MemoizedSelector<AdminManagementState, RolesSearchResponse> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.intelligenceRolesResponse,
  );

  /**
   * isRolesConfirmModalOpen
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isRolesConfirmModalOpen: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isRolesConfirmModalOpen,
  );

  /**
   * platformRoles
   * @static
   * @type {MemoizedSelector<AdminManagementState, PlatformRole[]>}
   * @memberof RolesSelectors
   */
  public static platformRoles: MemoizedSelector<AdminManagementState, PlatformRole[]> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.platformRoles,
  );

  /**
   * platformRoleByName
   * @static
   * @type {MemoizedSelector<AdminManagementState, Map<string, PlatformRole>>}
   * @memberof RolesSelectors
   */
  public static platformRoleByName: MemoizedSelector<AdminManagementState, Map<string, PlatformRole>> = createSelector(
    RolesSelectors.platformRoles,
    (platformRoles: PlatformRole[]) => {
      return platformRoles.reduce((platformRoleByNameMap: Map<string, PlatformRole>, role: PlatformRole) => {
        platformRoleByNameMap.set(role.name, role);
        return platformRoleByNameMap;
      }, new Map<string, PlatformRole>());
    },
  );

  /**
   * selectedExternalServiceRolesMap
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, Record<string, AccountRole[]>>>}
   * @memberof RolesSelectors
   */
  public static selectedExternalServiceRolesMap: MemoizedSelector<AdminManagementState, Record<string, Record<string, AccountRole[]>>> =
    createSelector(RolesSelectors.selectRolesState, (state: RolesState) => state.selectedExternalServiceRolesMap);

  /**
   * selectedIntelligenceRoles
   * @static
   * @type {MemoizedSelector<AdminManagementState, AccountRole[]>}
   * @memberof RolesSelectors
   */
  public static selectedIntelligenceRoles: MemoizedSelector<AdminManagementState, AccountRole[]> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.selectedIntelligenceRoles,
  );

  /**
   * selectedPlatformRoles
   * @static
   * @type {MemoizedSelector<AdminManagementState, PlatformRole[]>}
   * @memberof RolesSelectors
   */
  public static selectedPlatformRoles: MemoizedSelector<AdminManagementState, PlatformRole[]> = createSelector(
    RolesSelectors.platformRoles,
    (platformRoles: PlatformRole[]) => {
      return platformRoles.filter((platformRole: PlatformRole) => {
        return platformRole.isSelected;
      });
    },
  );

  /**
   * selectedServiceRolesList
   * @static
   * @type {MemoizedSelector<AdminManagementState, AccountRole[]>}
   * @memberof RolesSelectors
   */
  public static selectedServiceRolesList: MemoizedSelector<AdminManagementState, AccountRole[]> = createSelector(
    RolesSelectors.selectedIntelligenceRoles,
    RolesSelectors.selectedExternalServiceRolesMap,
    (selectedIntelligenceRoles: AccountRole[], externalServiceRolesByIntegrationId: Record<string, Record<string, AccountRole[]>>) => {
      let rolesList = [];
      Object.keys(externalServiceRolesByIntegrationId).forEach((integrationId: string) => {
        const orgRolesMap: Record<string, AccountRole[]> = externalServiceRolesByIntegrationId[integrationId];
        Object.keys(orgRolesMap).forEach((tenantId: string) => {
          rolesList = [...rolesList, ...orgRolesMap[tenantId]];
        });
      });
      return selectedIntelligenceRoles.concat(...rolesList);
    },
  );

  /**
   * selectedRoleNamesByService
   * @static
   * @type {MemoizedSelector<AdminManagementState, Map<IntegratedServiceType, string[]>>}
   * @memberof RolesSelectors
   */
  public static selectedRoleNamesByService: MemoizedSelector<AdminManagementState, Map<IntegratedServiceType, string[]>> = createSelector(
    RolesSelectors.selectedServiceRolesList,
    (selectedServiceRoles: AccountRole[]) => {
      return selectedServiceRoles.reduce((rolesNamesByServiceMap: Map<IntegratedServiceType, string[]>, role: AccountRole) => {
        const serviceType: IntegratedServiceType = role.serviceType as IntegratedServiceType;
        const roleNames: string[] = rolesNamesByServiceMap.get(serviceType) || [];
        roleNames.push(role.label);
        rolesNamesByServiceMap.set(serviceType, roleNames);
        return rolesNamesByServiceMap;
      }, new Map<IntegratedServiceType, string[]>());
    },
  );

  /**
   * serviceRoleById
   * @static
   * @type {MemoizedSelector<AdminManagementState, Map<string, AccountRole>>}
   * @memberof RolesSelectors
   */
  public static serviceRoleById: MemoizedSelector<AdminManagementState, Map<string, AccountRole>> = createSelector(
    RolesSelectors.intelligenceRolesResponse,
    RolesSelectors.externalServiceRolesMap,
    (intelligenceRolesResponse: RolesSearchResponse, externalServiceRolesMap: Record<string, AccountRole>) => {
      const serviceRoles = (intelligenceRolesResponse?.results ?? []).concat([...Object.values(externalServiceRolesMap)]);
      return new Map(serviceRoles.map((role: AccountRole) => [role.roleId, role]));
    },
  );

  /**
   * roleLabelFormatterFn
   * @static
   * @type {MemoizedSelector<AdminManagementState, (role: PlatformRole | ServiceRole) => string>}
   * @memberof RolesSelectors
   */
  public static roleLabelFormatterFn: MemoizedSelector<AdminManagementState, (role: PlatformRole | ServiceRole) => string> = createSelector(
    RolesSelectors.platformRoleByName,
    RolesSelectors.serviceRoleById,
    getRoleLabelFormatterFn,
  );

  /**
   * orgGroupsByIntegrationId
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, IntegrationOrgGroup[]>>}
   * @memberof RolesSelectors
   */
  public static orgGroupsByIntegrationId: MemoizedSelector<AdminManagementState, Record<string, IntegrationOrgGroup[]>> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.orgGroupsByIntegrationId,
  );

  /**
   * orgGroupNameById
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, string>>}
   * @memberof RolesSelectors
   */
  public static orgGroupNameById: MemoizedSelector<AdminManagementState, Record<string, string>> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => {
      const orgGroupNameById: Record<string, string> = {};
      const integrationOrgGroupDetailsFlattenedList: IntegrationOrgGroup[] = [].concat(...Object.values(state.orgGroupsByIntegrationId));
      integrationOrgGroupDetailsFlattenedList?.forEach((integrationOrgGroupDetails: IntegrationOrgGroup) => {
        orgGroupNameById[integrationOrgGroupDetails.tenantUuid] = integrationOrgGroupDetails.displayName;
      });
      return orgGroupNameById;
    },
  );

  /**
   * isLoadingAvailableRoles
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isLoadingAvailableRoles: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    RolesSelectors.intelligenceRolesLoadingStatus,
    (state: RolesState, intelligenceRolesLoadingStatus: LOAD_STATE) => {
      return [state.platformRolesLoadingState, intelligenceRolesLoadingStatus].includes(LOAD_STATE.IN_FLIGHT);
    },
  );

  /**
   * orgGroupsLoadStateByIntegrationId
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, boolean>>}
   * @memberof RolesSelectors
   */
  public static orgGroupsLoadStateByIntegrationId: MemoizedSelector<AdminManagementState, Record<string, boolean>> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.orgGroupsLoadStateByIntegrationId,
  );

  /**
   * orgGroupsLoadStateByIntegrationId
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, IntegrationOrgGroup>>}
   * @memberof RolesSelectors
   */
  public static selectedOrgGroupByIntegrationId: MemoizedSelector<AdminManagementState, Record<string, IntegrationOrgGroup>> =
    createSelector(RolesSelectors.selectRolesState, (state: RolesState) => state.selectedOrgGroupByIntegrationId);

  /**
   * selectedTenant
   * @static
   * @type {MemoizedSelector<AdminManagementState, IntegrationDetails>}
   * @memberof RolesSelectors
   */
  public static selectedTenant: MemoizedSelector<AdminManagementState, IntegrationDetails> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.selectedTenant,
  );

  /**
   * selectedExternalServiceRoles
   * @static
   * @type {MemoizedSelector<AdminManagementState, AccountRole[]>}
   * @memberof RolesSelectors
   */
  public static selectedExternalServiceRoles: MemoizedSelector<AdminManagementState, AccountRole[]> = createSelector(
    RolesSelectors.selectedExternalServiceRolesMap,
    RolesSelectors.selectedOrgGroupByIntegrationId,
    (
      externalServiceRolesByIntegrationId: Record<string, Record<string, AccountRole[]>>,
      selectedOrgGroupByIntegrationId: Record<string, IntegrationOrgGroup>,
    ) => {
      const integrationId = Object.keys(selectedOrgGroupByIntegrationId)[0];
      const tenantUuid = Object.values(selectedOrgGroupByIntegrationId)[0]?.tenantUuid;
      let rolesList: AccountRole[] = [];
      if (integrationId && tenantUuid && externalServiceRolesByIntegrationId) {
        rolesList = externalServiceRolesByIntegrationId[integrationId]?.[tenantUuid];
      }
      return rolesList;
    },
  );

  /**
   * isOrgGroupListLoadingForAnyIntegration
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isOrgGroupListLoadingForAnyIntegration: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) =>
      Object.values(state.orgGroupsLoadStateByIntegrationId).some(
        (isIntegrationOrgGroupListLoading: boolean) => isIntegrationOrgGroupListLoading,
      ),
  );

  /**
   * isCreateCustomRoleModalOpen
   * @static
   * @type {MemoizedSelector<AdminManagementState,boolean>}
   * @memberof RolesSelectors
   */
  public static isCreateCustomRoleModalOpen: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isCreateCustomRoleModalOpen,
  );

  /**
   * getSelectedRole
   * @static
   * @type {MemoizedSelector<AdminManagementState, AccountRole>}
   * @memberof RolesSelectors
   */
  public static getSelectedRole: MemoizedSelector<AdminManagementState, AccountRole> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.selectedRole,
  );

  /**
   * getScopeSetSearchRequest
   * @static
   * @type {MemoizedSelector<AdminManagementState, GenericSearchRequest>}
   * @memberof RolesSelectors
   */
  public static getScopeSetSearchRequest: MemoizedSelector<AdminManagementState, GenericSearchRequest> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.scopeSetSearchRequest,
  );

  /**
   * getScopeSetSearchResponse
   * @static
   * @type {MemoizedSelector<AdminManagementState, ScopeSetSearchResponse>}
   * @memberof RolesSelectors
   */
  public static getScopeSetSearchResponse: MemoizedSelector<AdminManagementState, ScopeSetSearchResponse> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.scopeSetSearchResponse,
  );

  /**
   * isScopeSetLoading
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isScopeSetLoading: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isScopeSetLoading,
  );

  /**
   * isEditingCustomRolePermissions
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isEditingCustomRolePermissions: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isEditingCustomRolePermissions,
  );

  /**
   * isDeleteCustomRoleModalActive
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isDeleteCustomRoleModalActive: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isDeleteCustomRoleModalActive,
  );

  /**
   * isSavingCustomRole
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isSavingCustomRole: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isSavingCustomRole,
  );

  /**
   * getScopeSetAccessById
   * @static
   * @type {MemoizedSelector<AdminManagementState, Record<string, ScopeSetAccessOption>>}
   * @memberof RolesSelectors
   */
  public static getScopeSetAccessById: MemoizedSelector<AdminManagementState, Record<string, ScopeSetAccessOption>> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.scopeSetAccessById,
  );

  /**
   * getAccountsByRoleIdResponse
   * @static
   * @type {MemoizedSelector<AdminManagementState, AccountsSearchResponse>}
   * @memberof RolesSelectors
   */
  public static getAccountsByRoleIdResponse: MemoizedSelector<AdminManagementState, AccountsSearchResponse> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.accountsByRoleIdResponse,
  );

  /**
   * isAccountsByRoleIdLoading
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isAccountsByRoleIdLoading: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isAccountsByRoleIdLoading,
  );

  /**
   * getSelectedAccountsToUnassign
   * @static
   * @type {MemoizedSelector<AdminManagementState, Account[]>}
   * @memberof RolesSelectors
   */
  public static getSelectedAccountsToUnassign: MemoizedSelector<AdminManagementState, Account[]> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.selectedAccountsToUnassign,
  );

  /**
   * getSelectedUserToAssign
   * @static
   * @type {MemoizedSelector<AdminManagementState, Account[]>}
   * @memberof RolesSelectors
   */
  public static getSelectedUserToAssign: MemoizedSelector<AdminManagementState, Account[]> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.selectedUsersToAssign,
  );

  /**
   * isAssignUsersModalActive
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isAssignUsersModalActive: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isAssignUsersModalActive,
  );

  /**
   * getAccountsByRoleIdRequest
   * @static
   * @type {MemoizedSelector<AdminManagementState, GenericSearchRequest>}
   * @memberof RolesSelectors
   */
  public static getAccountsByRoleIdRequest: MemoizedSelector<AdminManagementState, GenericSearchRequest> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.accountsByRoleIdRequest,
  );

  /**
   * getBulkRoleIdsToDelete
   * @static
   * @type {MemoizedSelector<AdminManagementState, string[]>}
   * @memberof RolesSelectors
   */
  public static getBulkRoleIdsToDelete: MemoizedSelector<AdminManagementState, string[]> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.bulkRoleIdsToDelete,
  );

  /**
   * getRoleToDelete
   * @static
   * @type {MemoizedSelector<AdminManagementState, AccountRole>}
   * @memberof RolesSelectors
   */
  public static getRoleToDelete: MemoizedSelector<AdminManagementState, AccountRole> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.roleToDelete,
  );

  /**
   * isUnassignAccountsModalActive
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof RolesSelectors
   */
  public static isUnassignAccountsModalActive: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.isUnassignAccountsModalActive,
  );

  /**
   * getAssignUsersListRequest
   * @static
   * @type {MemoizedSelector<AdminManagementState, GenericSearchRequest>}
   * @memberof RolesSelectors
   */
  public static getAssignUsersListRequest: MemoizedSelector<AdminManagementState, GenericSearchRequest> = createSelector(
    RolesSelectors.selectRolesState,
    (state: RolesState) => state.assignUsersListRequest,
  );

  /**
   * serviceRolesForAssignment
   * @static
   * @type {MemoizedSelector<AdminManagementState, ServiceRole[]>}
   * @memberof RolesSelectors
   */
  public static serviceRolesForAssignment: MemoizedSelector<AdminManagementState, ServiceRole[]> = createSelector(
    RolesSelectors.selectedIntelligenceRoles,
    RolesSelectors.selectedExternalServiceRolesMap,
    RolesSelectors.selectedOrgGroupByIntegrationId,
    UserPreferenceSelectors.integrationDetailsById,
    AdminUsersSelectors.selectedAdminUserListItem,
    AdminGroupsSelectors.selectedAdminGroupListItem,
    (
      selectedIntelligenceRoles: AccountRole[],
      externalServiceRolesByIntegrationId: Record<string, Record<string, AccountRole[]>>,
      selectedOrgGroupByIntegrationId: Record<string, IntegrationOrgGroup>,
      integrationDetailsById: Record<string, IntegrationDetails>,
      selectedAdminUserListItem: AdminUserListItem,
      selectedAdminGroupListItem: AdminGroupListItem,
    ) => {
      const groupMembershipType: string = AdminManagementConfig.GROUP_MEMBERSHIP_TYPE;
      const selectedAdminUserOrGroupListItem: AdminUserListItem | AdminGroupListItem =
        selectedAdminUserListItem || selectedAdminGroupListItem;
      let filteredSelectedIntelligenceRoles: AccountRole[] = [];
      // filter out group role assignments of selected admin user/group from selectedIntelligenceRoles
      // as only direct role assignments need to be saved
      // if selectedAdminUserOrGroupListItem is there, it means it is edit sceanrio
      if (selectedAdminUserOrGroupListItem) {
        filteredSelectedIntelligenceRoles = selectedIntelligenceRoles.filter((selectedRole: AccountRole) => {
          // find role if it is present in serviceRoles of selectedAdminUserOrGroupListItem
          const serviceRole: ServiceRole = selectedAdminUserOrGroupListItem.serviceRoles.find(
            (role: ServiceRole) => role.roleId === selectedRole.roleId && role.service === selectedRole.serviceType,
          );
          // filter it out if its membershipType is GROUP
          return serviceRole?.membershipType !== groupMembershipType;
        });
      }
      const serviceRoles: ServiceRole[] = getServiceRolesForAssignment(
        selectedAdminUserOrGroupListItem ? filteredSelectedIntelligenceRoles : selectedIntelligenceRoles,
        new IntegrationOrgGroup().tenantUuid,
        new IntegrationDetails(),
      );
      Object.keys(externalServiceRolesByIntegrationId).forEach((integrationId: string) => {
        const orgRolesMap: Record<string, AccountRole[]> = externalServiceRolesByIntegrationId[integrationId];
        Object.keys(orgRolesMap).forEach((tenantUuid: string) => {
          let filteredSelectedExternalServiceRoles: AccountRole[] = [];
          // filter out group role assignments of selected admin user/group from orgRolesMap[tenantUuid], roles for particular org
          // as only direct role assignments need to be saved
          // if selectedAdminUserOrGroupListItem is there, it means it is edit sceanrio
          if (selectedAdminUserOrGroupListItem) {
            filteredSelectedExternalServiceRoles = orgRolesMap[tenantUuid].filter((selectedRole: AccountRole) => {
              // find role if it is present in serviceRoles of selectedAdminUserOrGroupListItem
              const serviceRole: ServiceRole = selectedAdminUserOrGroupListItem.serviceRoles.find(
                (role: ServiceRole) =>
                  role.roleId === selectedRole.roleId &&
                  role.service === selectedRole.serviceType &&
                  role.assignedTenantId === tenantUuid &&
                  role.rootTenantId === integrationDetailsById[integrationId]?.tenantUuid,
              );
              // filter it out if its membershipType is GROUP
              return serviceRole?.membershipType !== groupMembershipType;
            });
          }
          serviceRoles.push(
            ...getServiceRolesForAssignment(
              selectedAdminUserOrGroupListItem ? filteredSelectedExternalServiceRoles : orgRolesMap[tenantUuid],
              tenantUuid,
              integrationDetailsById[integrationId],
            ),
          );
        });
      });
      return serviceRoles;
    },
  );

  /**
   * enabledIntegratedServiceToIntegrationMap
   * @static
   * @type {MemoizedSelector<AdminManagementState, Partial<Record<IntegratedServiceType, Integration>>>}
   * @memberof RolesSelectors
   */
  public static enabledIntegratedServiceToIntegrationMap: MemoizedSelector<
    AdminManagementState,
    Partial<Record<IntegratedServiceType, Integration>>
  > = createSelector(
    IntegratedServicesSelectors.allowedIntegratedServiceToIntegrationMap,
    UserPreferenceCommonSelectors.getFeatureControls,
    (integratedServiceToIntegrationMap: Record<IntegratedServiceType, Integration>, featureControls: FeatureControls) => {
      const filteredIntegratedServiceToIntegrationMap: Map<IntegratedServiceType, Integration> = new Map();
      Object.keys(integratedServiceToIntegrationMap).forEach((key: IntegratedServiceType) => {
        if (featureControls[AdminManagementConfig.SERVICE_TYPE_TO_FEATURE_FLAG_MAP[key]]) {
          filteredIntegratedServiceToIntegrationMap.set(key, integratedServiceToIntegrationMap[key]);
        }
      });
      return Object.fromEntries(filteredIntegratedServiceToIntegrationMap);
    },
  );
}
